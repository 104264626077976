<template>
	<mw-dialog v-model="dialog" cancelBtn submitBtn @submit="submit">
		<template v-slot:button>
			<nav-item
				@click="dialog = true"
				text="New Block"
				icon="mdi-plus-circle"
			></nav-item>
		</template>
		<template v-slot:dialog-body>
			<span class="v-label">Start and End</span>
			<date-picker
				v-model="range"
				range-separator=" - "
				type="datetime"
				range
				:show-second="false"
				:minute-options="[0, 15, 30, 45]"
				:disabled-date="disabledDate"
				format="DD/MM/YYYY HH:mm"
			></date-picker>
			<v-checkbox
				v-model="repeats"
				label="Slot repeats for
                 multiple days"
			></v-checkbox>
			<template v-if="repeats">
				<span class="v-label">Repeats</span>
				<date-picker
					v-model="repeatDays"
					type="date"
					multiple
					:showSecond="false"
					format="DD/MM"
				></date-picker>
			</template>
		</template>
	</mw-dialog>
</template>

<style lang="less">
.mx-datepicker {
	width: 100% !important;
}
</style>
<script>
import Vue from "vue";
// import MwDatePicker from "@c/ui/MwDatePicker";
import NavItem from "@c/navigation/Item";
import MwDialog from "@c/ui/MwDialog";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { arrayUnion } from "vuex-easy-firestore";
export default {
	name: "AddSlot",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			dialog: false,
			repeats: false,
			repeatDays: [],
			range: null,
		};
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
		start() {
			return this.range ? this.range[0] : null;
		},
		end() {
			return this.range ? this.range[1] : null;
		},
	},
	components: {
		DatePicker,
		NavItem,
		MwDialog,
	},
	methods: {
		disabledDate(date) {
			date;
			let start = this.project.start ? this.project.start.toDate() : null;
			let end = this.project.end ? this.project.end.toDate() : null;
			if( start ){
				if( date < start ){
					return true;
				}
			}
			if( end ){
				if( date > end ){
					return true;
				}
			}
			return;
		},
		simpleSlot() {
			return {
				id: this.mwutils.uniqueId(),
				start: new Date(this.start),
				end: new Date(this.end),
			};
		},
		repeaterSlot(date) {
			let start = Vue.moment(this.start).format("HH:mm");
			let end = Vue.moment(this.end).format("HH:mm");
			let day = Vue.moment(date).format("MM-DD-YYYY");
			return {
				id: this.mwutils.uniqueId(),
				start: Vue.moment(`${day} ${start}`).toDate(),
				end: Vue.moment(`${day} ${end}`).toDate(),
			};
		},
		reset() {
			this.range = null;
			this.repeats = false;
			this.repeatDays = null;
		},
		submit() {
			const self = this;
			let slots = [self.simpleSlot()];
			if (self.repeats) {
				self.repeatDays.forEach((day) => {
					slots.push(self.repeaterSlot(day));
				});
			}
			slots.forEach((slot) => {
				self.$store.dispatch("projects/patch", {
					id: self.id,
					slots: arrayUnion(slot),
				});
			});
			self.reset();
			self.dialog = false;
		},
	},
};
</script>
