<template>
	<v-sheet flat v-if="project">
		<h4>{{project.internal_name}}</h4>
		<v-row>
			<v-col>
				<v-text-field
					label="Session Length"
					hint="In minutes"
					persistent-hint
					type="number"
					:value="project.session_length"
					@input="(v) => update('session_length', v)"
				>
				</v-text-field>
			</v-col>
			<v-col>
				<v-text-field
					label="Minimum Break Length"
					type="number"
					:value="project.break_length"
					hint="In minutes"
					disabled
					persistent-hint
					@input="(v) => update('break_length', v)"
				>
				</v-text-field>
			</v-col>
		</v-row>
		<p><timezone-selector v-model="selectedTimezones" multiple></timezone-selector></p>
		<v-row>
			<v-data-table v-model="selected" :items="items" :headers="headers" show-select item-key="id">
				<template v-slot:footer.prepend>
					<v-btn v-if="selected.length" text color="error" @click="deleteSlots">Delete</v-btn>
					<export :columns="headers" :rows="items" ></export>
				</template>
			</v-data-table>
		</v-row>
		<secondary-navigation v-if="id" show-back>
			<template v-slot:main>
				<new-slot :id="id"></new-slot>
			</template>
			<template v-slot:append>
				<edit-project-notes v-model="id"></edit-project-notes>
			</template>
		</secondary-navigation>
	</v-sheet>
</template>

<script>
import SecondaryNavigation from "@c/navigation/Secondary";
import NewSlot from "@c/blocker/NewSlot";
import Export from "@c/downloads/Export";
import EditProjectNotes from '@c/edits/EditProjectNotes';
import TimezoneSelector from "@c/ui/TimezoneSelector";

export default {
	name: "EditProjectSlots",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			selected: [],
			selectedTimezones: []
		};
	},
	components: {
		SecondaryNavigation,
		NewSlot,
		Export,
		TimezoneSelector,
		EditProjectNotes
	},
	computed: {
	
		tz() {
			return this.$store.state.timezone;
		},
		project() {
			return this.$store.state.projects.data[this.id];
		},
		headers() {
			let headers = [
				{ text: this.tz, value: this.tz },
			];
			this.selectedTimezones.forEach( (tz) => {
				headers.push( { text: `${tz}`, value: `${tz}` } )
			} )
			return headers;
		},
		items() {
			const self = this;
			let slots = self.project.slots || [];
			slots = self.mwutils.sortByKey(slots, "start");
			return slots.map((slot ) => {
				let item = {
					id: slot.id,
					[this.tz]: `${this.mwtimeutils.formatAsDateTimeString( slot.start )} - ${this.mwtimeutils.formatAsDateTimeString( slot.end )}`
				};
				this.selectedTimezones.forEach( (tz) => {
					item[tz] = `${this.mwtimeutils.formatAsDateTimeString( slot.start , tz )} - ${this.mwtimeutils.formatAsDateTimeString( slot.end, tz  )}`
				} )
				return item;
			});
		},
	},

	methods: {
		update(key, value) {
			this.$store.dispatch("projects/patch", {
				id: this.id,
				[key]: value,
			});
		},
		deleteSlots( ){
			const self = this;
			let slots = self.project.slots; 
			slots = slots.filter( a => !self.selected.find( b => b.id == a.id ));
			self.$store.dispatch("projects/patch", {id: self.id, slots });
			self.selected = [];
		}
	},
	created() {
		this.$store.dispatch("projects/fetchById", this.id);
	},
};
</script>
