<template>
	<v-autocomplete
		:multiple="multiple"
		:chips="multiple"
		clearable
		:deletable-chips="multiple"
		:label="label"
		:items="items"
		:value="value"
		@change="(v) => $emit('input', v)"
	>
	</v-autocomplete>
</template>

<script>
export default {
	name: "TimezoneSelector",
	props: {
		value: { type: [Array, String] },
		multiple: { type: Boolean, default: false },
		label: { type: String, default: "Timezones" },
	},
	computed: {
		items() {
			return this.$store.state.timezones;
		},
	},
};
</script>
